import {
  startOfTomorrow,
  startOfToday,
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  add,
  startOfDay,
  endOfDay
} from 'date-fns';

export default () => [
  { name: 'overdue', from: new Date('2020-01-01'), to: startOfToday() },
  { name: 'today', from: startOfToday(), to: startOfTomorrow() },
  { name: 'tomorrow', from: startOfTomorrow(), to: addDays(startOfTomorrow(), 1) },
  { name: 'next 3 days', from: startOfToday(), to: addDays(startOfToday(), 4) },
  { name: 'this week', from: startOfWeek(new Date()), to: addDays(endOfWeek(new Date()), 1) },
  { name: 'no due date', from: null, to: null }
];

export function convertCustomRange({ from = {}, to = {} }) {
  let fromResult, toResult;
  if (from.refDate) {
    fromResult = getPrepairFunction(from.refDate)(new Date());
    if (from.offset && from.unit) {
      fromResult = add(fromResult, {
        [`${from.unit}s`]: +from.offset
      });
    }
    fromResult = startOfDay(fromResult);
  }
  if (to.refDate) {
    toResult = getPrepairFunction(to.refDate)(new Date());
    if (to.offset && to.unit) {
      toResult = add(toResult, {
        [`${to.unit}s`]: +to.offset
      });
    }
    toResult = endOfDay(toResult);
  }
  return { from: fromResult, to: toResult };
}
function getPrepairFunction(refDate) {
  switch (refDate) {
    case 'Today':
      return startOfToday;
    case 'Start of this week':
      return startOfWeek;
    case 'Start of this month':
      return startOfMonth;
    case 'Start of this year':
      return startOfYear;
    case 'End of this week':
      return endOfWeek;
    case 'End of this month':
      return endOfMonth;
    case 'End of this year':
      return endOfYear;
  }
}
