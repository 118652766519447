import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';
import { lw } from '@/utils/lw';

const UPDATE_INVENTION = am(`UPDATE_INVENTION`);
const GET_MY_COLLECTION = am(`GET_MY_COLLECTION`);
const ADD_TO_MY_COLLECTION = am(`ADD_TO_MY_COLLECTION`);
const REMOVE_FROM_MY_COLLECTION = am(`REMOVE_FROM_MY_COLLECTION`);
const GET_DOCKETING_INVENTION = am(`GET_DOCKETING_INVENTION`);

const LW_MY_COLLECTION = 'LW_MY_COLLECTION2';

export default {
  namespaced: true,
  ...factory({
    state: {
      my: null,
      isGetMyCollectionRequestPending: false,
      isGetMyCollectionRequestFailed: false,
      isGetRequestPending: false,
      isUpdating: false,
      isAddToMyCollectionPending: false,
      isRemoveFromMyCollectionPending: false
    },
    mutations: {
      [GET_MY_COLLECTION.STARTED](state) {
        state.my = null;
        state.isGetMyCollectionRequestFailed = false;
        state.isGetMyCollectionRequestPending = true;
      },
      [GET_MY_COLLECTION.COMPLETED](state, { data, total, size, from }) {
        state.my = {
          data,
          total,
          size,
          from
        };

        state.isGetMyCollectionRequestPending = false;
      },
      [GET_MY_COLLECTION.FAILED](state) {
        state.isGetMyCollectionRequestFailed = true;
        state.isGetMyCollectionRequestPending = false;
      },
      [UPDATE_INVENTION.STARTED](state) {
        state.isUpdating = true;
      },
      [UPDATE_INVENTION.COMPLETED](state, invention) {
        state.isUpdating = false;
        const existing = state.my.data.find(i => i.id === invention.id);

        if (!existing) {
          return;
        }

        existing.title = invention.title;
        existing.references = invention.references;

        if (state.item?.id === invention.id) {
          state.item.title = invention.title;
          state.item.references = invention.references;
        }
      },
      [UPDATE_INVENTION.FAILED](state) {
        state.isUpdating = false;
      },

      [ADD_TO_MY_COLLECTION.STARTED](state) {
        state.isAddToMyCollectionPending = true;
      },
      [ADD_TO_MY_COLLECTION.COMPLETED](state, item) {
        state.my.data.unshift(item);
        state.my.data = state.my.data.splice(0, 10);

        state.my.total = state.my.data.length;

        state.isAddToMyCollectionPending = false;
      },
      [ADD_TO_MY_COLLECTION.FAILED](state) {
        state.isAddToMyCollectionPending = false;
      },
      [REMOVE_FROM_MY_COLLECTION.STARTED](state) {
        state.isRemoveFromMyCollectionPending = true;
      },
      [REMOVE_FROM_MY_COLLECTION.COMPLETED](state, { id }) {
        state.my.data = state.my.data.filter(item => item.id !== id);
        state.my.total = state.my.data.length;
        state.isRemoveFromMyCollectionPending = false;
      },
      [REMOVE_FROM_MY_COLLECTION.FAILED](state) {
        state.isRemoveFromMyCollectionPending = false;
      },
      [GET_DOCKETING_INVENTION.STARTED](state) {
        state.isGetRequestPending = true;
        state.isGetRequestFailed = false;
      },
      [GET_DOCKETING_INVENTION.COMPLETED](state, { invention }) {
        state.isGetRequestPending = false;
        state.isGetRequestFailed = false;
        state.item = invention;
      },
      [GET_DOCKETING_INVENTION.FAILED](state) {
        state.isGetRequestPending = false;
        state.isGetRequestFailed = true;
        state.item = null;
      }
    },
    actions: {
      async my({ commit }) {
        try {
          commit(GET_MY_COLLECTION.STARTED);

          const collection = await Promise.all(
            (lw.get(LW_MY_COLLECTION) || []).filter(item => item.id).map(item => httpClient.get(`/api/inventions/${item.id}`).catch(e => null))
          );

          commit(GET_MY_COLLECTION.COMPLETED, {
            data: collection.filter(item => !!item),
            total: collection.filter(item => !!item).length
            // size: body.size || 10,
            // from: body.from || 0
          });
        } catch (e) {
          commit(GET_MY_COLLECTION.FAILED);
          throw e;
        }
      },
      async add({ commit }, item) {
        try {
          commit(ADD_TO_MY_COLLECTION.STARTED);
          const collection = lw.get(LW_MY_COLLECTION) || [];
          if (!collection.find(({ id }) => item.id === id)) {
            let newCollection = Array.from(collection);
            if (newCollection.length >= 10) {
              newCollection = newCollection.splice(0, 10);
              newCollection.splice(newCollection.length - 1, 1);
              newCollection.unshift(item);
            } else {
              newCollection.unshift(item);
            }
            lw.set(LW_MY_COLLECTION, newCollection);
          }

          commit(ADD_TO_MY_COLLECTION.COMPLETED, item);
        } catch (e) {
          commit(ADD_TO_MY_COLLECTION.FAILED);
          throw e;
        }
      },
      async remove({ commit }, { id }) {
        try {
          commit(REMOVE_FROM_MY_COLLECTION.STARTED);
          const collection = lw.get(LW_MY_COLLECTION) || [];

          lw.set(
            LW_MY_COLLECTION,
            collection.filter(item => item.id !== id)
          );

          commit(REMOVE_FROM_MY_COLLECTION.COMPLETED, { id });
        } catch (e) {
          commit(REMOVE_FROM_MY_COLLECTION.FAILED);
          throw e;
        }
      },
      async updateInvention({ commit }, { id, title, references }) {
        try {
          commit(UPDATE_INVENTION.STARTED);
          const updated = await httpClient.patch(`/api/inventions/${id}`, {
            title,
            references,
            referenceOverride: true
          });
          commit(UPDATE_INVENTION.COMPLETED, updated);
        } catch {
          commit(UPDATE_INVENTION.FAILED);
        }
      },
      async getByIdWithDocketing({ commit }, id) {
        try {
          commit(GET_DOCKETING_INVENTION.STARTED);
          const invention = await httpClient.get(`/api/inventions/${id}`);
          const matterNumber = invention.references?.find(r => r?.toLowerCase().startsWith('harrity:'));

          if (matterNumber) {
            try {
              invention.docketingInvention = await httpClient.get(`/api/fip-connector/matters/${matterNumber.split(':')[1]}/invention`);
            } catch (error) {
              invention.docketingInvention = null;
            }
          }

          commit(GET_DOCKETING_INVENTION.COMPLETED, { invention });

          return invention;
        } catch {
          commit(GET_DOCKETING_INVENTION.FAILED);
        }
      }
    }
  })(
    'inventions',
    {
      async getById(id) {
        return await httpClient.get(`/api/inventions/${id}`);
      },
      async getCollection({ text } = {}) {
        const size = 25;
        const response = await httpClient.get(`/api/inventions/search?qs=${text}&size=${size}`);

        return {
          ...response,
          size
        };
      },
      async create({ title, references }) {
        return httpClient.post('/api/inventions', {
          title,
          references
        });
      },
      async delete(id) {
        throw new Error('Not implemented.');
      },
      async update(id, args) {
        throw new Error('Not implemented.');
      }
    },
    {
      workflows: {
        namespaced: true,
        ...factory({
          state: {},
          mutations: {},
          actions: {}
        })('workflows', {
          async getById(id) {
            return httpClient.get(`/api/workflow/v2/workflows/${id}`);
          },
          async getCollection() {
            return httpClient.get(`/api/workflow/v2/workflows`);
          },
          async create() {
            throw new Error('Not implemented');
          },
          async delete() {
            throw new Error('Not implemented');
          },
          async update() {
            throw new Error('Not implemented');
          }
        })
      }
    }
  )
};
