import { factory, am } from '../utils';

import httpClient from '@/utils/httpClient';

export default {
  namespaced: true,
  ...factory({})('myTasksCount', {
    getCollection(filters = {}) {
      const qs = Object.entries(filters)
        .filter(([key, value]) => null != value)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return value.map(v => `${key}=${v}`).join('&');
          } else {
            return `${key}=${value}`;
          }
        })
        .join('&');
      return httpClient.get(`/api/streams/reports/tasks-progress?${qs}`);
    }
  })
};
