<template>
  <p-multiselect :key="value" :value="prepairedValue" :label="label" :placeholder="placeholder" :disabled="disabled"
    :search-change-callback="suggestAsync" :taggable="!single" :can-clear="false" :object="true" :filter-results="false"
    :select-on-blur="selectOnBlur" :no-options-text="'Search for people'" @change="change" @update:value="change">
  </p-multiselect>
</template>

<script>
import httpClient from '@/utils/httpClient';

import Multiselect from '@/components/common/Multiselect';
export default {
  components: {
    'p-multiselect': Multiselect
  },
  props: {
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Search for assignee'
    },
    value: {
      type: [Array, String],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    shorthand: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    mapper: {
      type: Function,
      default: undefined
    },
    selectOnBlur: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'update:value'],
  computed: {
    prepairedValue() {
      if (this.single) {
        return this.value ? { label: this.value, value: this.value } : null;
      }
      return Array.isArray(this.value) ? this.value.map(v => ({ label: v, value: v })) : this.value;
    }
  },
  methods: {
    change(value) {
      let prepairedValue = Array.isArray(value) ? value.map(v => v.value) : value?.value;;

      this.$emit('change', prepairedValue);
      this.$emit('update:value', prepairedValue);
    },
    async suggestAsync(q) {
      if (q && q.length) {
        const options = await httpClient.get(`/api/suggest/assignees?q=${q}`);

        return options.map(v => {
          let label, subLabel;
          if (v.firstName || v.lastName) {
            label = `${v.firstName} ${v.lastName}`;
            subLabel = v.email;
          } else if (v.name && !v.email) {
            label = v.name;
          } else {
            label = v.email.split('@')[0];
            subLabel = v.email;
          }
          return { label, subLabel, value: v.email || v.name };
        });
      }
      return [];
    }
  }
};
</script>
