import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';
const GET_DIR_INDEX = am(`GET_DIR_INDEX`);

export default {
  namespaced: true,
  ...factory({
    state: {
      loading: false,
      index: [],
      failed: false
    },
    getters: {
      root: state => reference => {
        return state.index[reference]?.path;
      }
    },
    mutations: {
      [GET_DIR_INDEX.STARTED](state) {
        state.loading = true;
        state.failed = false;
      },
      [GET_DIR_INDEX.COMPLETED](state, index) {
        const indexMap = index.reduce((acc, curr) => {
          acc[curr.reference] = curr;
          return acc;
        }, {});

        state.index = indexMap;
        state.loading = false;
        state.failed = false;
      },
      [GET_DIR_INDEX.FAILED](state) {
        state.index = [];
        state.loading = false;
        state.failed = true;
      }
    },
    actions: {
      async initialize({ commit }) {
        try {
          commit(GET_DIR_INDEX.STARTED);
          const index = await httpClient.get(`/api/documents-repository-connector/index`);
          commit(GET_DIR_INDEX.COMPLETED, index);
        } catch {
          commit(GET_DIR_INDEX.FAILED);
        }
      },
      async getTree({}, reference) {
        if (!reference) {
          return null;
        }

        try {
          return await httpClient.get(`/api/documents-repository-connector/index/${reference}`);
        } catch (error) {
          return null;
        }
      }
    }
  })('directoryIndex', {
    async getCollection() {
      throw new Error('Not implemented.');
    },
    async getById({ workflowId, milestoneId, stepId }) {
      throw new Error('Not implemented.');
    },
    async delete() {
      throw new Error('Not implemented.');
    },
    async update() {
      throw new Error('Not implemented.');
    }
  })
};
