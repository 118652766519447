export default {
  install(app, options) {
    app.config.globalProperties.$settings = {
      fip: {
        url:
          process.env.NODE_ENV === 'development'
            ? 'https://staging510.foundationip.com/foundationIP/navigation'
            : 'https://fip2.foundationip.com/foundationIP/navigation'
      }
    };
  }
};
